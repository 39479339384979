<template>
  <div class="container">
    <a-row class="grid-demo" :gutter="24">
      <!--      <a-col :span="6" class="leftCom">-->
      <!--        <InfoComponents/>-->
      <!--      </a-col>-->
      <a-col :span="24">
        <div class="content">
          <div class="tab">
            <div class="tabItem tabItemSelected">
              <div class="tabItemText">视频课程</div>
              <div class="tabItemTextBottom"></div>
            </div>
            <div @click="onToBeOpened" class="tabItem">
              <div class="tabItemText">直播答疑</div>
            </div>
          </div>
          <!--          <div class="lowerTab">-->
          <!--            <template v-for="(item, index) in courseTab" :key="index">-->
          <!--              <div-->
          <!--                  class="lowerTabItem"-->
          <!--                  :class="{'lowerTabItemSelected': courseTabSelected === index}"-->
          <!--                  @click="courseTabSelected = index"-->
          <!--              >-->
          <!--                {{ item }}-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </div>-->

          <div class="homeCollapse">
            <a-collapse :bordered="false" :default-active-key="defaultActiveKey">
              <a-collapse-item v-for="(itemCom,indexCom) in courseList" :style="customStyle" :key="indexCom">
                <template #header>
                  <div class="collapseHead">
                    <a-image
                        style="border-radius: 4px;flex-shrink: 0"
                        width="150"
                        height="70"
                        fit="cover"
                        :preview="false"
                        :src="itemCom.cover_image"
                    />
                    <div class="itemComTitle">{{ itemCom.title }}</div>
                  </div>
                </template>
                <template v-for="(item,index) in itemCom.list" :key="index">
                  <div class="accordion" style="border-bottom: 1px solid var(--color-border);">
                    <!--                  <div class="accordion">-->
                    <img
                        class="smallPicture"
                        :src="item.cover_image"
                    />
                    <div class="accordionContent">
                      <div class="accordionContentText">
                        {{ item.title }}
                      </div>
                      <div class="accordionContentTextDesc">
                        {{ item.description }}
                      </div>
                      <!--                      <div class="accordionContentProgress">-->
                      <!--                        <span>已学习100%</span> | 上次退出100%-->
                      <!--                      </div>-->
                    </div>
                    <a-button @click="onPlay(itemCom.course_id, index)" type="outline" size="mini">观看录播</a-button>
                  </div>
                </template>
              </a-collapse-item>
            </a-collapse>

          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
// import InfoComponents from "@/components/InfoComponents.vue";
import {GetCourseChaptersList, GetCoursesList} from "@/api/courses";
import {Message} from "@arco-design/web-vue";

const router = useRouter();
// const courseTab = ref(['文字课件', '视频课程'])
// const courseTabSelected = ref(0)
const courseList = ref([])

const defaultActiveKey = Array.from({length: 100}, (_, i) => i)

const customStyle = {
  borderRadius: '6px',
  border: 'none',
  overflow: 'hidden',
  background: '#ffffff',
}

function onPlay(course_id, index) {
  router.push({
    path: '/play',
    query: {
      course_id: course_id,
      index: index
    }
  })
}

onMounted(() => {
  init()
})

async function init() {
  try {
    const res = await GetCoursesList()
    courseList.value = res.data
    for (let i = 0; i < courseList.value.length; i++) {
      GetCourseChaptersList({
        course_id: courseList.value[i].course_id
      }).then(res => {
        console.log('卡机', res.data)
        courseList.value[i].list = res.data
      })
    }
    console.log(res.data)
  } catch (e) {
    console.log(e)
  }
}

function onToBeOpened() {
  Message.warning('待开放，敬请期待~')
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  box-sizing: border-box;
  padding: 30px 200px 0;
}

.content {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.tab {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px 30px;
  border-bottom: 1px solid var(--color-border);
}

.tabItem {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  color: var(--color-neutral-6);
}

.tabItemSelected {
  color: rgb(var(--primary-6));
  font-weight: bold;
}

.tabItemText {
  text-align: center;
}

.tabItemTextBottom {
  width: 100%;
  height: 2px;
  background-color: rgb(var(--primary-6));
  margin-top: 10px;
}

.lowerTab {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px 30px;
}

.lowerTabItem {
  margin-right: 20px;
  color: var(--color-neutral-6);
  box-sizing: border-box;
  padding: 8px 30px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.lowerTabItemSelected {
  background-color: rgb(var(--primary-6));
  color: white;
}

.accordion {
  padding: 20px 0;
  display: flex;
}

.accordionContent {
  flex: 1;
}

.accordionContentText {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  text-align: left;
  margin-left: 20px;
}

.accordionContentTextDesc {
  margin-top: 4px;
  font-size: 14px;
  display: flex;
  text-align: left;
  margin-left: 20px;
}

.accordionContentTextIcon {

}

.accordionContentProgress {
  line-height: 20px;
  font-size: 14px;
  color: var(--color-neutral-6);
  margin-top: 2px;
}

.accordionContentProgress span {
  color: rgb(var(--primary-6));
}

.collapseHead {
  display: flex;
}

.itemComTitle {
  font-weight: bold;
  margin-left: 20px;
  flex: 1;
  padding-top: 10px;
  text-align: left;
}

.smallPicture {
  width: 100px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .leftCom {
    display: none;
  }

  .accordion {
  //flex-direction: column; //align-items: flex-start;
  }

  .smallPicture {
    width: 60px;
    height: 30px;
  }

  .accordionContentText {
    margin-left: 10px;
    //margin-top: 10px;
    font-size: 15px;
  }

  .accordionContentTextDesc {
    margin-left: 10px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
