<template>
  <div class="container">
    <img class="background" src="@/assets/02.png"/>
    <div class="content">
      <img src="@/assets/log.jpg" alt="logo" class="logo"/>
      <div class="title" style="font-weight: bold;margin-bottom: 10px;color: white;">采购跃迁-AI重塑工作流</div>
      <div class="sub-title" style="margin-bottom: 30px;color: white;">输入您的电子邮件以登录您的帐户
      </div>
      <div v-if="errorMessage" class="login-form-error-msg">{{ errorMessage }}</div>
      <a-form
          ref="loginForm"
          :model="userInfo"
          class="login-form"
          layout="vertical"
          @submit="handleSubmit"
      >
        <a-form-item
            field="email"
            :rules="[{ required: true, message: '请输入邮箱' }]"
            :validate-trigger="['change', 'blur']"
            hide-label
        >
          <a-input
              v-model="userInfo.email"
              placeholder="请输入邮箱"
          >
          </a-input>
        </a-form-item>
        <a-form-item
            field="password"
            :rules="[{ required: true, message: '请输入密码' }]"
            :validate-trigger="['change', 'blur']"
            hide-label
        >
          <a-input-password
              v-model="userInfo.password"
              placeholder="请输入密码"
              allow-clear
          >
          </a-input-password>
        </a-form-item>
        <a-space :size="16" direction="vertical">
          <a-button type="primary" html-type="submit" long :loading="loading">
            登录
          </a-button>
          <a-button long @click="router.push('/register')">
            前往注册
          </a-button>
          <a-button @click="router.push('/forget')" type="text" long>忘记密码</a-button>
        </a-space>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive} from "vue";
import useLoading from "@/hooks/loading";
import {UserLogin} from "@/api/user";
import {useRouter} from "vue-router";
import {Message} from "@arco-design/web-vue";

const router = useRouter();
const {loading, setLoading} = useLoading();

const errorMessage = ref('');
const userInfo = reactive({
  email: "",
  password: "",
});

const handleSubmit = async ({errors, values}) => {
  if (loading.value) return;
  if (!errors) {
    errorMessage.value = ""
    setLoading(true);
    try {
      const res = await UserLogin(values)
      localStorage.setItem('user_name', res.data.user.user_name)
      localStorage.setItem('token', res.data.token)
      Message.success('登录成功')
      router.push('/')
    } catch (err) {
      errorMessage.value = err.message;
    } finally {
      setLoading(false);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.content {
  position: relative;
  width: 400px;
}

.title {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

.login-form-error-msg {
  color: red;
  margin-bottom: 20px;
}

.logo {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  margin-bottom: 20px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .content {
    width: 300px;
  }
}
</style>
