<template>
  <view class="largeContainer">
    <NavComponents v-if="router.currentRoute.value.path !== '/login' && router.currentRoute.value.path !== '/register'"/>
    <router-view class="smallContainer"/>
  </view>
</template>

<script setup>
import NavComponents from "@/components/NavComponents.vue";
import {useRouter} from "vue-router";

const router = useRouter();
</script>

<style>
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.largeContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.smallContainer {
  width: 100%;
  flex: 1;
}
</style>
