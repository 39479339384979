<template>
  <div class="NavComponents">
    <div class="actualContent">
      <img src="@/assets/log.jpg" alt="logo" class="logo2"/>
      <div class="logoRow">
        <div class="logo">采购跃迁</div>
        <div class="logo3">AI重塑工作流</div>
      </div>
      <div class="nav">
        <div class="navItem navItemSelected">学习中心</div>
      </div>
      <!--      <a-button @click="onCancellation" type="primary" status="danger" style="margin-right: 20px">退出登录</a-button>-->

      <div class="userName">{{ userName }}</div>
      <a-dropdown :popup-max-height="false">
        <a-avatar style="cursor: pointer">
          <img
              alt="avatar"
              src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp"
          />
        </a-avatar>
        <template #content>
          <a-doption @click="onCancellation">
            <template #icon>
              <IconExport/>
            </template>
            退出登录
          </a-doption>
        </template>
      </a-dropdown>
    </div>

    <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel" okText="确定退出" cancelText="取消"
             unmountOnClose>
      <template #title>
        提示
      </template>
      <div>是否确认退出登录？</div>
    </a-modal>
  </div>
</template>

<script>
import router from "@/router";
import {IconExport} from '@arco-design/web-vue/es/icon';

export default {
  components: {IconExport},
  name: 'NavComponents',
  props: {
    msg: String
  },
  data() {
    return {
      visible: false,
      userName: localStorage.getItem('user_name')
    }
  },
  methods: {
    handleOk() {
      this.visible = false
      localStorage.removeItem('token')
      router.push('/login')
    },
    handleCancel() {
      this.visible = false
    },
    onCancellation() {
      this.visible = true
    }
  }
}
</script>

<style scoped>
.NavComponents {
  width: 100%;
  height: 70px;
  background-color: white;
  padding: 0 200px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.actualContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

.nav {
  display: flex;
  margin-left: 100px;
  flex: 1;
}

.navItem {
  margin-left: 50px;
  font-size: 16px;
  cursor: pointer;
}

.navItem:hover {
  color: rgb(var(--primary-6));
}

.navItem:first-child {
  margin-left: 0;
}

.navItemSelected {
  color: rgb(var(--primary-6));
  font-weight: bold;
}

.logo2 {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 20px;
}

.logo3 {
  color: var(--color-neutral-6);
}

.logoRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userName {
  margin-right: 10px;
  font-weight: bold;
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .NavComponents {
    padding: 0 20px;
  }

  .logo2 {
    display: none;
  }

  .navItem {
    display: none;
  }

  .logo3 {
    display: none;
  }

  .userName {
    display: none;
  }
}
</style>
