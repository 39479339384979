import axios from 'axios';

export function GetCoursesList() {
    return axios.get('/courses');
}

export function GetCourseChaptersList(data) {
    return axios.get('/course_chapters',{
        params: data
    });
}
