import {createApp} from 'vue'
import App from './App.vue'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import router from './router'
import store from './store'
import '@/api/interceptor';

createApp(App).use(store).use(router).use(ArcoVue).mount('#app')

// 禁止右键
document.oncontextmenu = function () {
    event.returnValue = false;
}
document.oncontextmenu = function () {
    return false;
}
