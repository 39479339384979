import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: '首页',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            title: '登录',
            requiresAuth: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/RegisterView.vue'),
        meta: {
            title: '注册',
            requiresAuth: false
        }
    },
    {
        path: '/forget',
        name: 'forget',
        component: () => import('../views/ForgotPasswordView.vue'),
        meta: {
            title: '忘记密码',
            requiresAuth: false
        }
    },
    {
        path: '/play',
        name: 'play',
        component: () => import('../views/PlayView.vue'),
        meta: {
            title: '播放',
            requiresAuth: true
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // 在这里可以进行身份验证、权限检查、加载数据等
    if (to.meta.requiresAuth) {
        if (!localStorage.getItem('token')) {
            next('/login');
        }
    } else {
        next();
    }
    // 必须调用next()来resolve这个钩子
    next(); // 继续导航
    // next(false); // 中断导航
    // next('/path'); // 重定向到一个不同的地址
    // next(error); // (2.4.0+) 如果传入任何参数且它不是上述提到的类型，则导航会终止并且该参数会传递给路由器的onError处理器。
});

export default router
