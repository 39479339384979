import axios from 'axios';

export function UserSendCode(data) {
    return axios.post('/users/send/code', data);
}

export function UserRegister(data) {
    return axios.post('/users/register', data);
}

export function UserLogin(data) {
    return axios.post('/users/login', data);
}

export function UserResetPassword(data) {
    return axios.post('/users/resetpwd', data);
}
